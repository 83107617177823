var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',[_c('b-alert',{staticClass:"alert-fixed fixed-top rounded-1 m-4 bg-light",attrs:{"show":_vm.dismissCountDown,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_c('p',{staticClass:"float-end"},[_vm._v(_vm._s(_vm.dismissCountDown)+" วินาที...")]),_c('p',[_vm._v("แจ้งเตือน")]),_c('hr'),_c('p',[_vm._v(_vm._s(_vm.resalert))])])],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-bordered",attrs:{"border":"1"}},[_vm._m(0),_c('tbody',[_vm._m(1),_c('tr',[_c('th',{attrs:{"scope":"row","colspan":"2"}},[_vm._v(" เลขใบวิเคราะห์์ : "+_vm._s(_vm.diagnosis.diagCode)+" "),_c('br'),_vm._v(" ชื่อลูกค้า : "+_vm._s(_vm.diagnosis.customerFullNameTh)),_c('br'),_vm._v(" วันที่เกิดอาการ : "+_vm._s(_vm.diagnosis.diagDate)+" "),_c('br'),_vm._v(" ทะเบียนรถ : "+_vm._s(_vm.diagnosis.licensePlate)),_c('br'),_vm._v(" ยี่ห้อรถ : "+_vm._s(_vm.diagnosis.vehicleBrandEn)),_c('br'),_vm._v(" รุ่นรถ : "+_vm._s(_vm.diagnosis.vehicleModelEn)+" ")]),_c('th',{attrs:{"scope":"row","colspan":"5"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0301.text),expression:"diagnosis.diagResult.diagnosis.C0301.text"}],staticStyle:{"width":"100%","height":"100%"},attrs:{"cols":"100","rows":"10"},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C0301.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                      _vm.diagnosis.diagResult.diagnosis.C0301.text,
                      'C0301',
                      'TXT'
                    )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0301, "text", $event.target.value)}}})])]),_vm._m(2),_vm._m(3),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0401.val),expression:"diagnosis.diagResult.diagnosis.C0401.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0401.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0401.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0401.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0401.val,_vm.diagnosis.diagResult.diagnosis.C0401.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0401.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0401.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0401.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0401, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0401, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0401, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0401.val),
                          'C0401',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ด้านหน้ารถ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0501.val),expression:"diagnosis.diagResult.diagnosis.C0501.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0501.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0501.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0501.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0501.val,_vm.diagnosis.diagResult.diagnosis.C0501.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0501.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0501.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0501.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0501, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0501, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0501, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0501.val),
                          'C0501',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ขณะสตาร์ท/หลังติดเครื่องยนต์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0601.val),expression:"diagnosis.diagResult.diagnosis.C0601.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0601.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0601.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0601.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0601.val,_vm.diagnosis.diagResult.diagnosis.C0601.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0601.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0601.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0601.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0601, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0601, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0601, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0601.val),
                          'C0601',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เลี้ยว/เข้าโค้งซ้าย ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0701.val),expression:"diagnosis.diagResult.diagnosis.C0701.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0701.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0701.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0701.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0701.val,_vm.diagnosis.diagResult.diagnosis.C0701.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0701.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0701.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0701.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0701, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0701, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0701, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0701.val),
                          'C0701',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ไฟสูง ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0402.val),expression:"diagnosis.diagResult.diagnosis.C0402.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0402.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0402.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0402.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0402.val,_vm.diagnosis.diagResult.diagnosis.C0402.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0402.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0402.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0402.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0402, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0402, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0402, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0402.val),
                          'C0402',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ห้องเครื่องยนต์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0502.val),expression:"diagnosis.diagResult.diagnosis.C0502.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0502.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0502.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0502.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0502.val,_vm.diagnosis.diagResult.diagnosis.C0502.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0502.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0502.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0502.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0502, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0502, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0502, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0502.val),
                          'C0502',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ที่รอบเดินเบา ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0602.val),expression:"diagnosis.diagResult.diagnosis.C0602.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0602.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0602.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0602.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0602.val,_vm.diagnosis.diagResult.diagnosis.C0602.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0602.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0602.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0602.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0602, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0602, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0602, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0602.val),
                          'C0602',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เลี้ยว/เข้าโค้งขวา ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0702.val),expression:"diagnosis.diagResult.diagnosis.C0702.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0702.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0702.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0702.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0702.val,_vm.diagnosis.diagResult.diagnosis.C0702.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0702.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0702.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0702.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0702, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0702, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0702, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0702.val),
                          'C0702',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เปิดแอร์ ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0403.val),expression:"diagnosis.diagResult.diagnosis.C0403.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0403.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0403.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0403.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0403.val,_vm.diagnosis.diagResult.diagnosis.C0403.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0403.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0403.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0403.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0403, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0403, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0403, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0403.val),
                          'C0403',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" คอนโซลหน้า ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0503.val),expression:"diagnosis.diagResult.diagnosis.C0503.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0503.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0503.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0503.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0503.val,_vm.diagnosis.diagResult.diagnosis.C0503.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0503.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0503.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0503.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0503, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0503, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0503, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0503.val),
                          'C0503',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ตอนเข้าเกียร์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0603.val),expression:"diagnosis.diagResult.diagnosis.C0603.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0603.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0603.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0603.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0603.val,_vm.diagnosis.diagResult.diagnosis.C0603.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0603.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0603.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0603.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0603, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0603, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0603, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0603.val),
                          'C0603',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ตกหลุม/ข้ามหลังเต่า ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0703.val),expression:"diagnosis.diagResult.diagnosis.C0703.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0703.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0703.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0703.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0703.val,_vm.diagnosis.diagResult.diagnosis.C0703.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0703.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0703.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0703.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0703, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0703, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0703, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0703.val),
                          'C0703',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พัดลมเบอร์ 1 ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0404.val),expression:"diagnosis.diagResult.diagnosis.C0404.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0404.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0404.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0404.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0404.val,_vm.diagnosis.diagResult.diagnosis.C0404.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0404.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0404.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0404.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0404, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0404, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0404, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0404.val),
                          'C0404',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พวงมาลัย ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0504.val),expression:"diagnosis.diagResult.diagnosis.C0504.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0504.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0504.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0504.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0504.val,_vm.diagnosis.diagResult.diagnosis.C0504.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0504.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0504.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0504.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0504, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0504, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0504, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0504.val),
                          'C0504',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เร่งช้าๆ/ปานกลาง/รุนแรง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0604.val),expression:"diagnosis.diagResult.diagnosis.C0604.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0604.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0604.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0604.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0604.val,_vm.diagnosis.diagResult.diagnosis.C0604.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0604.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0604.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0604.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0604, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0604, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0604, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0604.val),
                          'C0604',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ขึ้นทางชัน/ลงทางชัน ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0704.val),expression:"diagnosis.diagResult.diagnosis.C0704.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0704.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0704.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0704.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0704.val,_vm.diagnosis.diagResult.diagnosis.C0704.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0704.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0704.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0704.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0704, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0704, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0704, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0704.val),
                          'C0704',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พัดลมเบอร์ 2 ")])])])]),_c('tr',[_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" มองเห็นได้ "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.sight.val),expression:"diagnosis.diagResult.diagnosis.sight.val"}],staticClass:"form-check-input",staticStyle:{"height":"40px","width":"40px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.sight.val,"checked":_vm.diagnosis.diagResult.diagnosis.sight.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.sight.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.sight.val,_vm.diagnosis.diagResult.diagnosis.sight.val)>-1:(_vm.diagnosis.diagResult.diagnosis.sight.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.sight.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.sight.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.sight, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.sight, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.sight, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                      _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.sight.val),
                      'sight',
                      'CHK'
                    )}]}})]),_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" มือสำผัสรู้สึกได้ "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.touch.val),expression:"diagnosis.diagResult.diagnosis.touch.val"}],staticClass:"form-check-input",staticStyle:{"height":"40px","width":"40px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.touch.val,"checked":_vm.diagnosis.diagResult.diagnosis.touch.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.touch.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.touch.val,_vm.diagnosis.diagResult.diagnosis.touch.val)>-1:(_vm.diagnosis.diagResult.diagnosis.touch.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.touch.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.touch.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.touch, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.touch, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.touch, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                      _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.touch.val),
                      'touch',
                      'CHK'
                    )}]}})])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0405.val),expression:"diagnosis.diagResult.diagnosis.C0405.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0405.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0405.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0405.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0405.val,_vm.diagnosis.diagResult.diagnosis.C0405.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0405.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0405.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0405.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0405, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0405, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0405, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0405.val),
                          'C0405',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" แป้นเบรก ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0505.val),expression:"diagnosis.diagResult.diagnosis.C0505.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0505.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0505.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0505.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0505.val,_vm.diagnosis.diagResult.diagnosis.C0505.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0505.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0505.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0505.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0505, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0505, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0505, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0505.val),
                          'C0505',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ความเร็วคงที่ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0605.val),expression:"diagnosis.diagResult.diagnosis.C0605.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0605.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0605.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0605.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0605.val,_vm.diagnosis.diagResult.diagnosis.C0605.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0605.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0605.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0605.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0605, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0605, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0605, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0605.val),
                          'C0605',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เปลี่ยนเกียร์ขึ้น/ลง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0705.val),expression:"diagnosis.diagResult.diagnosis.C0705.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0705.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0705.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0705.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0705.val,_vm.diagnosis.diagResult.diagnosis.C0705.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0705.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0705.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0705.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0705, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0705, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0705, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0705.val),
                          'C0705',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พัดลมเบอร์ 3 ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0406.val),expression:"diagnosis.diagResult.diagnosis.C0406.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0406.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0406.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0406.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0406.val,_vm.diagnosis.diagResult.diagnosis.C0406.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0406.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0406.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0406.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0406, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0406, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0406, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0406.val),
                          'C0406',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" แป้นคันเร่ง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0506.val),expression:"diagnosis.diagResult.diagnosis.C0506.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0506.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0506.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0506.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0506.val,_vm.diagnosis.diagResult.diagnosis.C0506.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0506.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0506.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0506.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0506, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0506, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0506, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0506.val),
                          'C0506',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ชะลอความเร็ว ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0606.val),expression:"diagnosis.diagResult.diagnosis.C0606.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0606.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0606.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0606.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0606.val,_vm.diagnosis.diagResult.diagnosis.C0606.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0606.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0606.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0606.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0606, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0606, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0606, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0606.val),
                          'C0606',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" จอดรถ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0706.val),expression:"diagnosis.diagResult.diagnosis.C0706.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0706.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0706.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0706.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0706.val,_vm.diagnosis.diagResult.diagnosis.C0706.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0706.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0706.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0706.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0706, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0706, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0706, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0706.val),
                          'C0706',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พัดลมเบอร์ 4 ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0407.val),expression:"diagnosis.diagResult.diagnosis.C0407.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0407.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0407.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0407.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0407.val,_vm.diagnosis.diagResult.diagnosis.C0407.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0407.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0407.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0407.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0407, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0407, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0407, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0407.val),
                          'C0407',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" แป้นคลัทช์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0507.val),expression:"diagnosis.diagResult.diagnosis.C0507.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0507.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0507.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0507.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0507.val,_vm.diagnosis.diagResult.diagnosis.C0507.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0507.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0507.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0507.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0507, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0507, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0507, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0507.val),
                          'C0507',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เลียเบรก ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0607.val),expression:"diagnosis.diagResult.diagnosis.C0607.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0607.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0607.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0607.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0607.val,_vm.diagnosis.diagResult.diagnosis.C0607.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0607.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0607.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0607.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0607, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0607, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0607, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0607.val),
                          'C0607',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" จราจรติดขัด ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0707.val),expression:"diagnosis.diagResult.diagnosis.C0707.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0707.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0707.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0707.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0707.val,_vm.diagnosis.diagResult.diagnosis.C0707.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0707.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0707.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0707.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0707, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0707, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0707, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0707.val),
                          'C0707',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พัดลมเบอร์ 5 ")])])])]),_c('tr',[_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" ได้่ยิน "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.hearing.val),expression:"diagnosis.diagResult.diagnosis.hearing.val"}],staticClass:"form-check-input",staticStyle:{"height":"40px","width":"40px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.hearing.val,"checked":_vm.diagnosis.diagResult.diagnosis.hearing.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.hearing.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.hearing.val,_vm.diagnosis.diagResult.diagnosis.hearing.val)>-1:(_vm.diagnosis.diagResult.diagnosis.hearing.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.hearing.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.hearing.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.hearing, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.hearing, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.hearing, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                      _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.hearing.val),
                      'hearing',
                      'CHK'
                    )}]}})]),_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" ได้กลิ่น "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.smell.val),expression:"diagnosis.diagResult.diagnosis.smell.val"}],staticClass:"form-check-input",staticStyle:{"height":"40px","width":"40px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.smell.val,"checked":_vm.diagnosis.diagResult.diagnosis.smell.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.smell.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.smell.val,_vm.diagnosis.diagResult.diagnosis.smell.val)>-1:(_vm.diagnosis.diagResult.diagnosis.smell.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.smell.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.smell.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.smell, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.smell, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.smell, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                      _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.smell.val),
                      'smell',
                      'CHK'
                    )}]}})])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0408.val),expression:"diagnosis.diagResult.diagnosis.C0408.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0408.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0408.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0408.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0408.val,_vm.diagnosis.diagResult.diagnosis.C0408.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0408.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0408.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0408.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0408, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0408, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0408, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0408.val),
                          'C0408',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" คันเกียร์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0508.val),expression:"diagnosis.diagResult.diagnosis.C0508.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0508.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0508.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0508.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0508.val,_vm.diagnosis.diagResult.diagnosis.C0508.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0508.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0508.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0508.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0508, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0508, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0508, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0508.val),
                          'C0508',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เบรกแบบปกติ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C06ETC01.val),expression:"diagnosis.diagResult.diagnosis.C06ETC01.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C06ETC01.val,"checked":_vm.diagnosis.diagResult.diagnosis.C06ETC01.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C06ETC01.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C06ETC01.val,_vm.diagnosis.diagResult.diagnosis.C06ETC01.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C06ETC01.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C06ETC01.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C06ETC01.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC01, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC01, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC01, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C06ETC01.val
                          ),
                          'C06ETC01',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C06ETC01.text),expression:"diagnosis.diagResult.diagnosis.C06ETC01.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C06ETC01.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C06ETC01.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C06ETC01.text,
                          'C06ETC01',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC01, "text", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0708.val),expression:"diagnosis.diagResult.diagnosis.C0708.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0708.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0708.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0708.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0708.val,_vm.diagnosis.diagResult.diagnosis.C0708.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0708.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0708.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0708.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0708, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0708, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0708, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0708.val),
                          'C0708',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ไฟไล่ฝ้า ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0409.val),expression:"diagnosis.diagResult.diagnosis.C0409.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0409.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0409.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0409.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0409.val,_vm.diagnosis.diagResult.diagnosis.C0409.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0409.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0409.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0409.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0409, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0409, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0409, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0409.val),
                          'C0409',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เบาะนั่ง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0509.val),expression:"diagnosis.diagResult.diagnosis.C0509.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0509.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0509.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0509.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0509.val,_vm.diagnosis.diagResult.diagnosis.C0509.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0509.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0509.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0509.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0509, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0509, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0509, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0509.val),
                          'C0509',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เบรกแบบกะทันหัน ")])])]),_vm._m(4),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0709.val),expression:"diagnosis.diagResult.diagnosis.C0709.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0709.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0709.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0709.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0709.val,_vm.diagnosis.diagResult.diagnosis.C0709.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0709.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0709.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0709.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0709, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0709, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0709, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0709.val),
                          'C0709',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ที่ปัดน้ำฝน ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0410.val),expression:"diagnosis.diagResult.diagnosis.C0410.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0410.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0410.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0410.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0410.val,_vm.diagnosis.diagResult.diagnosis.C0410.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0410.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0410.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0410.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0410, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0410, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0410, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0410.val),
                          'C0410',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" หลังรถ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0510.val),expression:"diagnosis.diagResult.diagnosis.C0510.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0510.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0510.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0510.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0510.val,_vm.diagnosis.diagResult.diagnosis.C0510.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0510.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0510.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0510.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0510, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0510, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0510, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0510.val),
                          'C0510',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เหยียบคลัทช์/ ปล่อยคลัทช์ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0710.val),expression:"diagnosis.diagResult.diagnosis.C0710.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0710.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0710.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0710.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0710.val,_vm.diagnosis.diagResult.diagnosis.C0710.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0710.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0710.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0710.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0710, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0710, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0710, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0710.val),
                          'C0710',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" วิทยุ ")])])])]),_c('tr',[_c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"colspan":"2"}},[_vm._v(" 2. ตำแหน่งที่พบอาการ ")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0411.val),expression:"diagnosis.diagResult.diagnosis.C0411.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0411.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0411.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0411.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0411.val,_vm.diagnosis.diagResult.diagnosis.C0411.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0411.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0411.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0411.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0411, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0411, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0411, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0411.val),
                          'C0411',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" หลังคา ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C05ETC.val),expression:"diagnosis.diagResult.diagnosis.C05ETC.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C05ETC.val,"checked":_vm.diagnosis.diagResult.diagnosis.C05ETC.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C05ETC.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C05ETC.val,_vm.diagnosis.diagResult.diagnosis.C05ETC.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C05ETC.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C05ETC.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C05ETC.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C05ETC, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C05ETC, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C05ETC, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C05ETC.val
                          ),
                          'C05ETC',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C05ETC.text),expression:"diagnosis.diagResult.diagnosis.C05ETC.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C05ETC.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C05ETC.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C05ETC.text,
                          'C05ETC',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C05ETC, "text", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0608.val),expression:"diagnosis.diagResult.diagnosis.C0608.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0608.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0608.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0608.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0608.val,_vm.diagnosis.diagResult.diagnosis.C0608.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0608.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0608.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0608.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0608, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0608, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0608, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0608.val),
                          'C0608',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เรียบ/ลาดยาง/คอนกรีต ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0711.val),expression:"diagnosis.diagResult.diagnosis.C0711.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0711.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0711.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0711.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0711.val,_vm.diagnosis.diagResult.diagnosis.C0711.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0711.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0711.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0711.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0711, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0711, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0711, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0711.val),
                          'C0711',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เครื่องเล่นCD ")])])])]),_c('tr',[_c('th',{attrs:{"colspan":"2","rowspan":"19"}},[_c('diag',{attrs:{"imgCar":_vm.img,"svId":_vm.$route.params.svId,"diagId":_vm.$route.params.diagId}})],1)]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0412.val),expression:"diagnosis.diagResult.diagnosis.C0412.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0412.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0412.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0412.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0412.val,_vm.diagnosis.diagResult.diagnosis.C0412.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0412.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0412.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0412.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0412, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0412, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0412, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0412.val),
                          'C0412',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" พื้นรถ ")])])]),_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0609.val),expression:"diagnosis.diagResult.diagnosis.C0609.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0609.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0609.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0609.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0609.val,_vm.diagnosis.diagResult.diagnosis.C0609.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0609.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0609.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0609.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0609, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0609, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0609, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0609.val),
                          'C0609',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ขรุขระ/ไม่เรียบ/หลุมบ่อ ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0712.val),expression:"diagnosis.diagResult.diagnosis.C0712.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0712.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0712.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0712.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0712.val,_vm.diagnosis.diagResult.diagnosis.C0712.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0712.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0712.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0712.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0712, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0712, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0712, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0712.val),
                          'C0712',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เนวิเกเตอร์ ")])])])]),_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0413.val),expression:"diagnosis.diagResult.diagnosis.C0413.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0413.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0413.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0413.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0413.val,_vm.diagnosis.diagResult.diagnosis.C0413.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0413.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0413.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0413.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0413, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0413, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0413, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0413.val),
                          'C0413',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ใต้ท้องรถ ")])])]),_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0610.val),expression:"diagnosis.diagResult.diagnosis.C0610.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0610.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0610.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0610.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0610.val,_vm.diagnosis.diagResult.diagnosis.C0610.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0610.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0610.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0610.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0610, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0610, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0610, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0610.val),
                          'C0610',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เปียกลื่น/ทราย/ลูกรัง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C07ETC.val),expression:"diagnosis.diagResult.diagnosis.C07ETC.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C07ETC.val,"checked":_vm.diagnosis.diagResult.diagnosis.C07ETC.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C07ETC.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C07ETC.val,_vm.diagnosis.diagResult.diagnosis.C07ETC.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C07ETC.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C07ETC.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C07ETC.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C07ETC, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C07ETC, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C07ETC, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C07ETC.val
                          ),
                          'C07ETC',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C07ETC.text),expression:"diagnosis.diagResult.diagnosis.C07ETC.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C07ETC.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C07ETC.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C07ETC.text,
                          'C07ETC',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C07ETC, "text", $event.target.value)}}})])])])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C04ETC.val),expression:"diagnosis.diagResult.diagnosis.C04ETC.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C04ETC.val,"checked":_vm.diagnosis.diagResult.diagnosis.C04ETC.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C04ETC.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C04ETC.val,_vm.diagnosis.diagResult.diagnosis.C04ETC.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C04ETC.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C04ETC.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C04ETC.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C04ETC, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C04ETC, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C04ETC, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C04ETC.val
                          ),
                          'C04ETC',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C04ETC.text),expression:"diagnosis.diagResult.diagnosis.C04ETC.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C04ETC.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C04ETC.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C04ETC.text,
                          'C04ETC',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C04ETC, "text", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C06ETC02.val),expression:"diagnosis.diagResult.diagnosis.C06ETC02.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C06ETC02.val,"checked":_vm.diagnosis.diagResult.diagnosis.C06ETC02.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C06ETC02.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C06ETC02.val,_vm.diagnosis.diagResult.diagnosis.C06ETC02.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C06ETC02.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C06ETC02.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C06ETC02.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC02, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC02, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC02, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C06ETC02.val
                          ),
                          'C06ETC02',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C06ETC02.text),expression:"diagnosis.diagResult.diagnosis.C06ETC02.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C06ETC02.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C06ETC02.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C06ETC02.text,
                          'C06ETC02',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C06ETC02, "text", $event.target.value)}}})])])]),_c('td')]),_vm._m(5),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0801.val),expression:"diagnosis.diagResult.diagnosis.C0801.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0801.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0801.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0801.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0801.val,_vm.diagnosis.diagResult.diagnosis.C0801.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0801.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0801.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0801.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0801, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0801, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0801, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0801.val),
                          'C0801',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 0 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0901.val),expression:"diagnosis.diagResult.diagnosis.C0901.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0901.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0901.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0901.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0901.val,_vm.diagnosis.diagResult.diagnosis.C0901.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0901.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0901.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0901.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0901, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0901, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0901, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0901.val),
                          'C0901',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 550 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v(" เกียร์ธรรมดา ")]),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M1.val),expression:"diagnosis.diagResult.diagnosis.M1.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M1.val,"checked":_vm.diagnosis.diagResult.diagnosis.M1.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M1.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M1.val,_vm.diagnosis.diagResult.diagnosis.M1.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M1.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M1.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M1.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M1, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M1, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M1, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M1.val),
                          'M1',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 1 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-1"},[_vm._v(" จำนวนผู้โดยสาร "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.passenger.text),expression:"diagnosis.diagResult.diagnosis.passenger.text"}],staticClass:"form-control",staticStyle:{"text-align":"center"},attrs:{"type":"number","disabled":_vm.diagnosis.diagResult.diagnosis.passenger.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.passenger.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.passenger.text,
                          'passenger',
                          'TXT'
                        )},"change":function($event){return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.passenger.text,
                          'passenger',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.passenger, "text", $event.target.value)}}}),_vm._v("คน ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0802.val),expression:"diagnosis.diagResult.diagnosis.C0802.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0802.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0802.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0802.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0802.val,_vm.diagnosis.diagResult.diagnosis.C0802.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0802.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0802.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0802.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0802, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0802, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0802, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0802.val),
                          'C0802',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 1-20 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0902.val),expression:"diagnosis.diagResult.diagnosis.C0902.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0902.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0902.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0902.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0902.val,_vm.diagnosis.diagResult.diagnosis.C0902.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0902.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0902.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0902.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0902, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0902, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0902, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0902.val),
                          'C0902',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 1000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M2.val),expression:"diagnosis.diagResult.diagnosis.M2.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M2.val,"checked":_vm.diagnosis.diagResult.diagnosis.M2.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M2.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M2.val,_vm.diagnosis.diagResult.diagnosis.M2.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M2.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M2.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M2.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M2, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M2, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M2, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M2.val),
                          'M2',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 2 ")])])]),_c('td',[_c('div',{staticClass:"col-12 mt-1"},[_vm._v(" น้ำหนัก "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.payload.text),expression:"diagnosis.diagResult.diagnosis.payload.text"}],staticClass:"form-control",staticStyle:{"text-align":"center"},attrs:{"type":"number","disabled":_vm.diagnosis.diagResult.diagnosis.payload.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.payload.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                        _vm.diagnosis.diagResult.diagnosis.payload.text,
                        'payload',
                        'TXT'
                      )},"change":function($event){return _vm.addDiagDataChoice(
                        _vm.diagnosis.diagResult.diagnosis.payload.text,
                        'payload',
                        'TXT'
                      )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.payload, "text", $event.target.value)}}}),_vm._v(" Kg. ")])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0803.val),expression:"diagnosis.diagResult.diagnosis.C0803.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0803.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0803.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0803.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0803.val,_vm.diagnosis.diagResult.diagnosis.C0803.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0803.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0803.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0803.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0803, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0803, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0803, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0803.val),
                          'C0803',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 20-40 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0903.val),expression:"diagnosis.diagResult.diagnosis.C0903.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0903.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0903.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0903.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0903.val,_vm.diagnosis.diagResult.diagnosis.C0903.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0903.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0903.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0903.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0903, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0903, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0903, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0903.val),
                          'C0903',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 2000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M3.val),expression:"diagnosis.diagResult.diagnosis.M3.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M3.val,"checked":_vm.diagnosis.diagResult.diagnosis.M3.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M3.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M3.val,_vm.diagnosis.diagResult.diagnosis.M3.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M3.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M3.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M3.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M3, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M3, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M3, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M3.val),
                          'M3',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 3 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-1"},[_vm._v(" ขับระยะสั้น "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.shortDrive.text),expression:"diagnosis.diagResult.diagnosis.shortDrive.text"}],staticClass:"form-control",staticStyle:{"text-align":"center"},attrs:{"type":"number","disabled":_vm.diagnosis.diagResult.diagnosis.shortDrive.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.shortDrive.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.shortDrive.text,
                          'shortDrive',
                          'TXT'
                        )},"change":function($event){return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.shortDrive.text,
                          'shortDrive',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.shortDrive, "text", $event.target.value)}}}),_vm._v("km ต่อวัน ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0804.val),expression:"diagnosis.diagResult.diagnosis.C0804.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0804.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0804.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0804.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0804.val,_vm.diagnosis.diagResult.diagnosis.C0804.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0804.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0804.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0804.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0804, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0804, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0804, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0804.val),
                          'C0804',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 40-60 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0904.val),expression:"diagnosis.diagResult.diagnosis.C0904.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0904.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0904.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0904.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0904.val,_vm.diagnosis.diagResult.diagnosis.C0904.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0904.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0904.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0904.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0904, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0904, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0904, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0904.val),
                          'C0904',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 3000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M4.val),expression:"diagnosis.diagResult.diagnosis.M4.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M4.val,"checked":_vm.diagnosis.diagResult.diagnosis.M4.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M4.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M4.val,_vm.diagnosis.diagResult.diagnosis.M4.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M4.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M4.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M4.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M4, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M4, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M4, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M4.val),
                          'M4',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 4 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-1"},[_vm._v(" ขับระยะยาว "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.diagnosis.diagResult.diagnosis.longDistanceDrive.text
                      ),expression:"\n                        diagnosis.diagResult.diagnosis.longDistanceDrive.text\n                      "}],staticClass:"form-control",staticStyle:{"text-align":"center"},attrs:{"type":"number","disabled":_vm.diagnosis.diagResult.diagnosis.longDistanceDrive
                          .val == false},domProps:{"value":(
                        _vm.diagnosis.diagResult.diagnosis.longDistanceDrive.text
                      )},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.longDistanceDrive
                            .text,
                          'longDistanceDrive',
                          'TXT'
                        )},"change":function($event){return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.longDistanceDrive
                            .text,
                          'longDistanceDrive',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.longDistanceDrive, "text", $event.target.value)}}}),_vm._v("km ต่อวัน ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0805.val),expression:"diagnosis.diagResult.diagnosis.C0805.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0805.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0805.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0805.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0805.val,_vm.diagnosis.diagResult.diagnosis.C0805.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0805.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0805.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0805.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0805, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0805, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0805, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0805.val),
                          'C0805',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 60-80 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0905.val),expression:"diagnosis.diagResult.diagnosis.C0905.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0905.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0905.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0905.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0905.val,_vm.diagnosis.diagResult.diagnosis.C0905.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0905.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0905.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0905.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0905, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0905, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0905, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0905.val),
                          'C0905',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 4000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M5.val),expression:"diagnosis.diagResult.diagnosis.M5.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M5.val,"checked":_vm.diagnosis.diagResult.diagnosis.M5.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M5.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M5.val,_vm.diagnosis.diagResult.diagnosis.M5.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M5.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M5.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M5.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M5, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M5, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M5, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M5.val),
                          'M5',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 5 ")])])]),_c('td',{attrs:{"rowspan":"10"}})]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0806.val),expression:"diagnosis.diagResult.diagnosis.C0806.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0806.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0806.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0806.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0806.val,_vm.diagnosis.diagResult.diagnosis.C0806.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0806.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0806.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0806.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0806, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0806, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0806, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0806.val),
                          'C0806',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 80-100 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0906.val),expression:"diagnosis.diagResult.diagnosis.C0906.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0906.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0906.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0906.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0906.val,_vm.diagnosis.diagResult.diagnosis.C0906.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0906.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0906.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0906.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0906, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0906, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0906, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0906.val),
                          'C0906',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 5000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.M6.val),expression:"diagnosis.diagResult.diagnosis.M6.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.M6.val,"checked":_vm.diagnosis.diagResult.diagnosis.M6.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.M6.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.M6.val,_vm.diagnosis.diagResult.diagnosis.M6.val)>-1:(_vm.diagnosis.diagResult.diagnosis.M6.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.M6.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.M6.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M6, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.M6, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.M6, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.M6.val),
                          'M6',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" 6 ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0807.val),expression:"diagnosis.diagResult.diagnosis.C0807.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0807.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0807.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0807.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0807.val,_vm.diagnosis.diagResult.diagnosis.C0807.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0807.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0807.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0807.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0807, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0807, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0807, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0807.val),
                          'C0807',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 100-120 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0907.val),expression:"diagnosis.diagResult.diagnosis.C0907.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0907.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0907.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0907.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0907.val,_vm.diagnosis.diagResult.diagnosis.C0907.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0907.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0907.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0907.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0907, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0907, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0907, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0907.val),
                          'C0907',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" < 6000 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.MN.val),expression:"diagnosis.diagResult.diagnosis.MN.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.MN.val,"checked":_vm.diagnosis.diagResult.diagnosis.MN.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.MN.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.MN.val,_vm.diagnosis.diagResult.diagnosis.MN.val)>-1:(_vm.diagnosis.diagResult.diagnosis.MN.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.MN.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.MN.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.MN, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.MN, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.MN, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.MN.val),
                          'MN',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" N ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0808.val),expression:"diagnosis.diagResult.diagnosis.C0808.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0808.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0808.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0808.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0808.val,_vm.diagnosis.diagResult.diagnosis.C0808.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0808.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0808.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0808.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0808, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0808, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0808, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0808.val),
                          'C0808',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 120-140 ")])])]),_vm._m(6),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.MR.val),expression:"diagnosis.diagResult.diagnosis.MR.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.MR.val,"checked":_vm.diagnosis.diagResult.diagnosis.MR.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.MR.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.MR.val,_vm.diagnosis.diagResult.diagnosis.MR.val)>-1:(_vm.diagnosis.diagResult.diagnosis.MR.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.MR.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.MR.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.MR, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.MR, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.MR, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.MR.val),
                          'MR',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" R ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td'),_vm._m(7),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v(" เกียร์อัตโนมัติ ")]),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AP.val),expression:"diagnosis.diagResult.diagnosis.AP.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AP.val,"checked":_vm.diagnosis.diagResult.diagnosis.AP.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AP.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AP.val,_vm.diagnosis.diagResult.diagnosis.AP.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AP.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AP.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AP.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AP, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AP, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AP, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AP.val),
                          'AP',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" P ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"rowspan":"5"}}),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AR.val),expression:"diagnosis.diagResult.diagnosis.AR.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AR.val,"checked":_vm.diagnosis.diagResult.diagnosis.AR.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AR.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AR.val,_vm.diagnosis.diagResult.diagnosis.AR.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AR.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AR.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AR.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AR, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AR, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AR, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AR.val),
                          'AR',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" R ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0908.val),expression:"diagnosis.diagResult.diagnosis.C0908.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0908.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0908.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0908.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0908.val,_vm.diagnosis.diagResult.diagnosis.C0908.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0908.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0908.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0908.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0908, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0908, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0908, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0908.val),
                          'C0908',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เย็น ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AN.val),expression:"diagnosis.diagResult.diagnosis.AN.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AN.val,"checked":_vm.diagnosis.diagResult.diagnosis.AN.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AN.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AN.val,_vm.diagnosis.diagResult.diagnosis.AN.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AN.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AN.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AN.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AN, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AN, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AN, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AN.val),
                          'AN',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" N ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0909.val),expression:"diagnosis.diagResult.diagnosis.C0909.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0909.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0909.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0909.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0909.val,_vm.diagnosis.diagResult.diagnosis.C0909.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0909.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0909.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0909.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0909, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0909, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0909, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0909.val),
                          'C0909',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อุณหภูมิทำงาน ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AD.val),expression:"diagnosis.diagResult.diagnosis.AD.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AD.val,"checked":_vm.diagnosis.diagResult.diagnosis.AD.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AD.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AD.val,_vm.diagnosis.diagResult.diagnosis.AD.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AD.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AD.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AD.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AD, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AD, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AD, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AD.val),
                          'AD',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" D ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C0910.val),expression:"diagnosis.diagResult.diagnosis.C0910.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C0910.val,"checked":_vm.diagnosis.diagResult.diagnosis.C0910.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C0910.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C0910.val,_vm.diagnosis.diagResult.diagnosis.C0910.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C0910.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C0910.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C0910.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0910, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0910, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C0910, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C0910.val),
                          'C0910',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ร้อนจัด ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AM.val),expression:"diagnosis.diagResult.diagnosis.AM.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AM.val,"checked":_vm.diagnosis.diagResult.diagnosis.AM.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AM.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AM.val,_vm.diagnosis.diagResult.diagnosis.AM.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AM.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AM.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AM.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AM, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AM, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AM, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AM.val),
                          'AM',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" M ")])])])]),_c('tr',{staticClass:"text-center"},[_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"}),_c('div',{staticClass:"col-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.AS.val),expression:"diagnosis.diagResult.diagnosis.AS.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.AS.val,"checked":_vm.diagnosis.diagResult.diagnosis.AS.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.AS.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.AS.val,_vm.diagnosis.diagResult.diagnosis.AS.val)>-1:(_vm.diagnosis.diagResult.diagnosis.AS.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.AS.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.AS.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AS, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.AS, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.AS, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.AS.val),
                          'AS',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-5 mt-1"},[_vm._v(" SPORT MODE ")])])])]),_vm._m(8),_vm._m(9),_c('tr',{staticClass:"text-center"},[_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" ผู้ร่วมทดสอบกับลูกค้า ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("ลายเซ็น")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1201.val),expression:"diagnosis.diagResult.diagnosis.C1201.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1201.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1201.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1201.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1201.val,_vm.diagnosis.diagResult.diagnosis.C1201.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1201.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1201.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1201.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1201, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1201, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1201, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1201.val),
                          'C1201',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ต่ำกว่า 20 องศา C ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1301.val),expression:"diagnosis.diagResult.diagnosis.C1301.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1301.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1301.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1301.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1301.val,_vm.diagnosis.diagResult.diagnosis.C1301.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1301.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1301.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1301.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1301, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1301, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1301, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1301.val),
                          'C1301',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" กลางแดดจัด ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1401.val),expression:"diagnosis.diagResult.diagnosis.C1401.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1401.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1401.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1401.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1401.val,_vm.diagnosis.diagResult.diagnosis.C1401.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1401.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1401.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1401.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1401, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1401, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1401, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1401.val),
                          'C1401',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เบนซิน 91/95 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1501.val),expression:"diagnosis.diagResult.diagnosis.C1501.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1501.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1501.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1501.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1501.val,_vm.diagnosis.diagResult.diagnosis.C1501.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1501.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1501.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1501.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1501.val),
                          'C1501',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" บ่อยมาก ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("ที่ปรึกษาด้านบริการ")]),_c('th',[_vm._v("_____________")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1202.val),expression:"diagnosis.diagResult.diagnosis.C1202.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1202.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1202.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1202.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1202.val,_vm.diagnosis.diagResult.diagnosis.C1202.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1202.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1202.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1202.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1202, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1202, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1202, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1202.val),
                          'C1202',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 20-25 องศา C ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1302.val),expression:"diagnosis.diagResult.diagnosis.C1302.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1302.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1302.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1302.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1302.val,_vm.diagnosis.diagResult.diagnosis.C1302.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1302.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1302.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1302.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1302, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1302, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1302, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1302.val),
                          'C1302',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ลมพัดแรง ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1402.val),expression:"diagnosis.diagResult.diagnosis.C1402.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1402.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1402.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1402.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1402.val,_vm.diagnosis.diagResult.diagnosis.C1402.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1402.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1402.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1402.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1402, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1402, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1402, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1402.val),
                          'C1402',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" แก๊สโซฮอล 91/95 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1501.val),expression:"diagnosis.diagResult.diagnosis.C1501.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1501.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1501.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1501.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1501.val,_vm.diagnosis.diagResult.diagnosis.C1501.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1501.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1501.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1501.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1501, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1501.val),
                          'C1501',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เช้า/เที่ยง/บ่าย/ค่ำ ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Master Technician")]),_c('th',[_vm._v("_____________")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1203.val),expression:"diagnosis.diagResult.diagnosis.C1203.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1203.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1203.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1203.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1203.val,_vm.diagnosis.diagResult.diagnosis.C1203.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1203.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1203.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1203.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1203, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1203, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1203, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1203.val),
                          'C1203',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 25-30 องศา C ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1303.val),expression:"diagnosis.diagResult.diagnosis.C1303.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1303.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1303.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1303.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1303.val,_vm.diagnosis.diagResult.diagnosis.C1303.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1303.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1303.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1303.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1303, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1303, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1303, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1303.val),
                          'C1303',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ฝนตกโปรย/ปกติ/หนัก ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1403.val),expression:"diagnosis.diagResult.diagnosis.C1403.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1403.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1403.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1403.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1403.val,_vm.diagnosis.diagResult.diagnosis.C1403.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1403.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1403.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1403.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1403, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1403, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1403, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1403.val),
                          'C1403',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" E20 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1503.val),expression:"diagnosis.diagResult.diagnosis.C1503.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1503.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1503.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1503.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1503.val,_vm.diagnosis.diagResult.diagnosis.C1503.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1503.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1503.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1503.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1503, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1503, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1503, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1503.val),
                          'C1503',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เวลา ____:____ ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("หัวหน้าช่างเทคนิค")]),_c('th',[_vm._v("_____________")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1204.val),expression:"diagnosis.diagResult.diagnosis.C1204.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1204.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1204.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1204.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1204.val,_vm.diagnosis.diagResult.diagnosis.C1204.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1204.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1204.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1204.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1204, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1204, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1204, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1204.val),
                          'C1204',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 30-35 องศา C ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C13ETC.val),expression:"diagnosis.diagResult.diagnosis.C13ETC.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C13ETC.val,"checked":_vm.diagnosis.diagResult.diagnosis.C13ETC.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C13ETC.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C13ETC.val,_vm.diagnosis.diagResult.diagnosis.C13ETC.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C13ETC.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C13ETC.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C13ETC.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C13ETC, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C13ETC, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C13ETC, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C13ETC.val
                          ),
                          'C13ETC',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C13ETC.text),expression:"diagnosis.diagResult.diagnosis.C13ETC.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C13ETC.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C13ETC.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C13ETC.text,
                          'C13ETC',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C13ETC, "text", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1404.val),expression:"diagnosis.diagResult.diagnosis.C1404.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1404.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1404.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1404.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1404.val,_vm.diagnosis.diagResult.diagnosis.C1404.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1404.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1404.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1404.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1404, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1404, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1404, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1404.val),
                          'C1404',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" E85 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1504.val),expression:"diagnosis.diagResult.diagnosis.C1504.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1504.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1504.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1504.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1504.val,_vm.diagnosis.diagResult.diagnosis.C1504.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1504.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1504.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1504.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1504, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1504, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1504, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1504.val),
                          'C1504',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" สัปดาห์ละครั้ง ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("ช่างเทคนิค")]),_c('th',[_vm._v("_____________")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1205.val),expression:"diagnosis.diagResult.diagnosis.C1205.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1205.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1205.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1205.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1205.val,_vm.diagnosis.diagResult.diagnosis.C1205.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1205.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1205.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1205.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1205, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1205, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1205, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1205.val),
                          'C1205',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 35-40 องศา C ")])])]),_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1405.val),expression:"diagnosis.diagResult.diagnosis.C1405.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1405.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1405.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1405.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1405.val,_vm.diagnosis.diagResult.diagnosis.C1405.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1405.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1405.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1405.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1405, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1405, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1405, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1405.val),
                          'C1405',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ดีเซล ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1505.val),expression:"diagnosis.diagResult.diagnosis.C1505.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1505.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1505.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1505.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1505.val,_vm.diagnosis.diagResult.diagnosis.C1505.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1505.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1505.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1505.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1505, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1505, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1505, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1505.val),
                          'C1505',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" เดือนละครั้ง ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("ผู้จัดการศูนย์บริการ")]),_c('th',[_vm._v("_____________")]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1206.val),expression:"diagnosis.diagResult.diagnosis.C1206.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1206.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1206.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1206.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1206.val,_vm.diagnosis.diagResult.diagnosis.C1206.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1206.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1206.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1206.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1206, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1206, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1206, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1206.val),
                          'C1206',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" 40 องศา C ขึ้นไป ")])])]),_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1406.val),expression:"diagnosis.diagResult.diagnosis.C1406.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1406.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1406.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1406.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1406.val,_vm.diagnosis.diagResult.diagnosis.C1406.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1406.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1406.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1406.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1406, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1406, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1406, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1406.val),
                          'C1406',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" B7 ")])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1506.val),expression:"diagnosis.diagResult.diagnosis.C1506.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1506.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1506.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1506.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1506.val,_vm.diagnosis.diagResult.diagnosis.C1506.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1506.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1506.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1506.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1506, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1506, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1506, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1506.val),
                          'C1506',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" บางครั้งบางคราว ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("ลูกค้า")]),_c('th',[_vm._v("_____________")]),_c('td'),_c('td'),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C14ETC.val),expression:"diagnosis.diagResult.diagnosis.C14ETC.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C14ETC.val,"checked":_vm.diagnosis.diagResult.diagnosis.C14ETC.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C14ETC.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C14ETC.val,_vm.diagnosis.diagResult.diagnosis.C14ETC.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C14ETC.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C14ETC.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C14ETC.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C14ETC, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C14ETC, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C14ETC, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(
                            _vm.diagnosis.diagResult.diagnosis.C14ETC.val
                          ),
                          'C14ETC',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" อื่นๆ ให้ระบุ : "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C14ETC.text),expression:"diagnosis.diagResult.diagnosis.C14ETC.text"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.diagnosis.diagResult.diagnosis.C14ETC.val == false},domProps:{"value":(_vm.diagnosis.diagResult.diagnosis.C14ETC.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addDiagDataChoice(
                          _vm.diagnosis.diagResult.diagnosis.C14ETC.text,
                          'C14ETC',
                          'TXT'
                        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.diagnosis.diagResult.diagnosis.C14ETC, "text", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.C1507.val),expression:"diagnosis.diagResult.diagnosis.C1507.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.C1507.val,"checked":_vm.diagnosis.diagResult.diagnosis.C1507.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.C1507.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.C1507.val,_vm.diagnosis.diagResult.diagnosis.C1507.val)>-1:(_vm.diagnosis.diagResult.diagnosis.C1507.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.C1507.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.C1507.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1507, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1507, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.C1507, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.C1507.val),
                          'C1507',
                          'CHK'
                        )}]}})]),_c('div',{staticClass:"col-9 mt-1"},[_vm._v(" ระบุไม่ได้ ")])])])]),_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("พบอาการร่วมกับลูกค้า")]),_c('th',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.diagnosis.diagResult.diagnosis.found.val),expression:"diagnosis.diagResult.diagnosis.found.val"}],staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.found.val,"checked":_vm.diagnosis.diagResult.diagnosis.found.val == true,"checked":Array.isArray(_vm.diagnosis.diagResult.diagnosis.found.val)?_vm._i(_vm.diagnosis.diagResult.diagnosis.found.val,_vm.diagnosis.diagResult.diagnosis.found.val)>-1:(_vm.diagnosis.diagResult.diagnosis.found.val)},on:{"change":[function($event){var $$a=_vm.diagnosis.diagResult.diagnosis.found.val,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.diagnosis.diagResult.diagnosis.found.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.found, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.diagnosis.diagResult.diagnosis.found, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.diagnosis.diagResult.diagnosis.found, "val", $$c)}},function($event){_vm.addDiagDataChoice(
                          _vm.checktrue(_vm.diagnosis.diagResult.diagnosis.found.val),
                          'found',
                          'CHK'
                        )}]}}),_vm._v(" พบ ")]),_c('div',{staticClass:"col-6 mt-1"},[_c('input',{staticClass:"form-check-input",staticStyle:{"height":"20px","width":"20px"},attrs:{"type":"checkbox"},domProps:{"value":_vm.diagnosis.diagResult.diagnosis.found.val,"checked":_vm.diagnosis.diagResult.diagnosis.found.val == false},on:{"change":function($event){return _vm.addDiagDataChoice(0, 'found', 'CHK')}}}),_vm._v(" ไม่พบ ")])])]),_c('td',{attrs:{"colspan":"4"}})])])])])]),_c('div',{staticClass:"card"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"mx-0 text-center",staticStyle:{"background-color":"#404040"}},[_c('th',{attrs:{"scope":"col","colspan":"8"}},[_c('h4',{staticStyle:{"color":"white"}},[_vm._v(" ใบวิเคราะห์ปัญหา"),_c('br'),_vm._v("Diagnosis form ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"scope":"row","colspan":"2"}},[_vm._v(" รายละเอียดรถยนต์ ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"scope":"row","colspan":"5"}},[_vm._v(" 3. ลักษณะอาการ/ลักษณะเสียง ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"scope":"row","colspan":"2"}},[_vm._v(" 1.ระบุชี้ชัดบริเวณต่างๆ ที่พบอาการด้วยประสาทสัมผัสที่พบ ")]),_c('th',{staticClass:"text-center",staticStyle:{"background-color":"#C1C1C1"},attrs:{"scope":"row","colspan":"5"}},[_vm._v(" สภาพการขับขี่ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2","rowspan":"5"}},[_vm._v(" วิธีการ "),_c('br'),_vm._v(" - ทำเครื่องหมาย (ü) ที่ประสาทสัมผัส "),_c('br'),_vm._v(" - ทำเครื่องหมายวงกลมตำแหน่งที่พบอาการตามภาพด้านล่าง "),_c('br'),_vm._v(" - วงกลมคำในตารางด้านขวาเพื่อเพิ่มความชัดเจนในการช่วยวิเคราะห์ ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("4. บริเวณที่พบอาการ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("5. สภาวะการเกิดปัญหา")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("6. สภาวะการขับขี่")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" 7. อุปกรณ์ไฟฟ้าที่ใช้งาน ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"rowspan":"2"}},[_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center mx-auto"},[_c('br'),_c('b',[_vm._v("สภาพผิวถนน")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" 8. ความเร็วรถยนต์ km/hr ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" 9. ความเร็วรอบเครื่องยนต์ ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("10. ตำแหน่งเกียร์")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" 11. การใช้รถโดยทั่วไป ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9 mt-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"rowspan":"2"}},[_c('br'),_vm._v("อุณหภูมิเครื่องยนต์ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"text-center"},[_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" ผู้สอบถามอาการ ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" ลายเซ็น ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"},attrs:{"colspan":"4"}},[_vm._v(" สภาพแวดล้อม ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"text-center"},[_c('th',[_vm._v(" ที่ปรึกษาด้านบริการ ")]),_c('th',[_vm._v(" _____________ ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("12. อุณหภูมิภายนอก")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("13. สภาพอากาศ")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v("14. น้ำมันเชื้อเพลิง")]),_c('th',{staticStyle:{"background-color":"#C1C1C1"}},[_vm._v(" 15. ความถี่ที่พบอาการ ")])])
}]

export { render, staticRenderFns }