<template>
  <div>
    <canvas
      ref="can"
      id="can"
      width="390"
      height="662"
      style="border: double; "
    ></canvas
    ><br />
    <button class="btn btn-success" @click="dataURLtoFile()">เสร็จสิ้น</button>
    <!-- <button class="btn btn-success" @click="saveImg()">Save</button> -->
  </div>
</template>
<script>
import { fabric } from "fabric";
import useNetw from "@useNetw";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
// const fs = require("fs");
export default {
  components: {},
  name: "diag",
  props: { imgCar: String, svId: String, diagId: String },
  computed: {},
  data() {
    return {
      img: "",
    };
  },
  methods: {
    dataURLtoFile() {
      // const ref = this.$refs.can;
      var canvas = document.getElementById("can");
      // console.log(canvas);

      var img = canvas.toDataURL("image/png");
      console.log("immage =>", img);

      // var data = img.replace(/^data:image\/\w+;base64,/, "");
      // var buf = new Buffer(data, "base64");
      const file = require("fs").writeFile("out.png", img, "base64", function(err) {
        console.log('err=>', err);
      });

      localStorage.setItem("imgData",file );
      console.log("local = >", localStorage);
      this.putImgDiag();
    },

    //Usage example:

    putImgDiag() {
      console.log(`${this.imgCar} - ${this.svId} - ${this.diagId}`);
      const bodyFormData = new FormData();
      bodyFormData.append("svId", this.svId);
      bodyFormData.append("diagId", this.diagId);
      bodyFormData.append("inputName", "C0201");
      bodyFormData.append("inputVal", localStorage.imgData);

      useNetw
        .post("api/service/diagnosis/update-signature", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    saveImg() {
      const ref = this.$refs.can;
      const canvas = new fabric.Canvas(ref);
      this.img = canvas.toDataURL("image/png");
      console.log(this.img);
      // this.getdataimg();
    },
  },
  mounted() {
    const ref = this.$refs.can;
    const canvas = new fabric.Canvas(ref, {
      isDrawingMode: true,
    });
    canvas.freeDrawingBrush.color = "red";
    canvas.freeDrawingBrush.width = 5;
    fabric.Image.fromURL(`data:image/png;base64,${this.imgCar}`, (img) => {
      canvas.backgroundImage = img;
      canvas.renderAll();
    });
    // console.log("REF=>", canvas);
  },
};
</script>

<style></style>
